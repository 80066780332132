<template>
    <div class="personal-box" id="personal-box">
        <div class="avatar">
            <img :src="userInfo.avatarUrl" alt="" width="75" height="75" v-show="userInfo.avatarUrl" @click="goNextPage('editPersonal')">
            <div class="phoneNum" @click="goNextPage('editPersonal')" v-show="userInfo.phoneNum">{{ userInfo.phoneNum.slice(0,3) }}****{{ userInfo.phoneNum.slice(7,11) }}</div>
        </div>
        <div class="row">
            <div class="item" @click="goNextPage('allorder')">
                <img src="../../assets/images/all-order.png" alt="" width="30" height="30">
                <div>全部</div>
            </div>
            <div class="item" @click="goNextPage('paying')">
                <Badge :amount="totalAmount.orderPaying" :type="'order'" :width="'18px'">
                    <template v-slot:amount>
                        <img src="../../assets/images/paying.png" alt="" width="30" height="30">
                        <div>待付款</div>
                    </template>
                </Badge>
            </div>
            <div class="item" @click="goNextPage('toBeDelivered')">
                <Badge :amount="totalAmount.orderPaid + totalAmount.orderPicking" :type="'order'" :width="'18px'">
                    <template v-slot:amount>
                        <img src="../../assets/images/to-be-delivered.png" alt="" width="30" height="30">
                        <div>待发货</div>
                    </template>
                </Badge>
            </div>
            <div class="item" @click="goNextPage('toBeReceived')">
                <Badge :amount="totalAmount.orderReceived" :type="'order'" :width="'18px'">
                    <template v-slot:amount>
                        <img src="../../assets/images/to-be-received.png" alt="" width="30" height="30">
                        <div>待收货</div>
                    </template>
                </Badge>
            </div>
            <div class="item" @click="goNextPage('customerService')">
                <img src="../../assets/images/customer-service.png" alt="" width="30" height="30">
                <div>售后</div>
            </div>
        </div>
        <div class="panel">
            <div class="item" @click="goNextPage('address')">
                <span>我的地址</span>
                <img src="../../assets/images/enter.png" alt="" width="20" height="20">
            </div>
            <div class="item" @click="goNextPage('coupon')">
                <span>我的卡券</span>
                <img src="../../assets/images/enter.png" alt="" width="20" height="20">
            </div>
            <div class="item" @click="goNextPage('service')">
                <span>我的客服</span>
                <img src="../../assets/images/enter.png" alt="" width="20" height="20">
            </div>
            <div class="item" @click="goNextPage('create')">
                <span>发布文章</span>
                <img src="../../assets/images/enter.png" alt="" width="20" height="20">
            </div>
            <!-- <div class="item" @click="goNextPage('draft')">
                <span>我的草稿</span>
                <img src="../../assets/images/enter.png" alt="" width="20" height="20">
            </div> -->
            <div class="item" @click="goNextPage('home')">
                <span>浏览文章</span>
                <img src="../../assets/images/enter.png" alt="" width="20" height="20">
            </div>
            <!-- <div class="item" @click="goNextPage('author')">
                <span>我的文章</span>
                <img src="../../assets/images/enter.png" alt="" width="20" height="20">
            </div> -->
            <div class="item" @click="goNextPage('feedback')">
                <span>意见反馈</span>
                <img src="../../assets/images/enter.png" alt="" width="20" height="20">
            </div>
            <div class="item" @click="goNextPage('about')">
                <span>关于{{ appName }}</span>
                <img src="../../assets/images/enter.png" alt="" width="20" height="20">
            </div>
        </div>
        <logout ref="logout" />
        <login-reminder ref="loginReminder" style="bottom:0;"/>
    </div>
</template>

<script>
import { queryUserByPhoneNum, wxSdk, querStatusOrderGoodsTotalAmount } from '@/api/api'
import wx from "weixin-js-sdk";
import Logout from '../../components/dialog/Logout.vue';
import LoginReminder from '../../components/dialog/LoginReminder.vue';
// import { Toast } from 'vant';
const seo = require('../../../config/index')
import { getopenIdByCode } from '@/utils/index';
import Badge from '../../components/badge/index.vue'

export default {
    name: 'Personal',
    components: {
        Logout,
        Badge,
        LoginReminder,
    },
    mounted(){
    },
    deactivated() {
    },
    data:()=>{
        return {
            userInfo:{
                nickname: '',
                introduct: '',
                avatarUrl: '',
                phoneNum: '',
                BGUrl: '',
                fansAmount: 0,
                followAmount: 0,
                getLikedTotal:0,
                totalPageViews:0,
                member_level: '',
                partner_level: '',
                member_point: '',
            },
            totalAmount:{
                orderPaying: 0,
                orderPaid: 0,
                orderPicking: 0,
                orderReceived: 0
            },
            appName: seo.config.appName,
            isLogin: localStorage.getItem('token') ? true : false,
        }
    },
    methods:{
        getUserInfo(){
            this.$store.commit('Set_Loading_State', true)
            queryUserByPhoneNum({ phoneNum: localStorage.getItem('phoneNumber') }).then(res=>{
                localStorage.setItem('userInfo', JSON.stringify(res.data.data))
                this.userInfo.nickname = JSON.parse(localStorage.getItem('userInfo'))[0].nickname
                this.userInfo.introduct = JSON.parse(localStorage.getItem('userInfo'))[0].intro
                this.userInfo.phoneNum = JSON.parse(localStorage.getItem('userInfo'))[0].phoneNum
                if(JSON.parse(localStorage.getItem('userInfo'))[0].miniAvatarUrl){
                    this.userInfo.avatarUrl = JSON.parse(localStorage.getItem('userInfo'))[0].miniAvatarUrl
                } else {
                    this.userInfo.avatarUrl = require('../../assets/images/avatar.png')
                }
                this.seoInit()
                setTimeout(()=>{
                    this.$store.commit('Set_Loading_State', false)
                },300)
            })
        },
        initOrderAmount(){
            let uid = this.isLogin ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
            querStatusOrderGoodsTotalAmount({ uid: uid, order_status: ['orderPaying','orderPaid','orderPicking','orderReceived']}).then(res=>{
                let resData = res.data.data
                resData.forEach(item=>{
                    if(item.name === 'orderPaying'){
                        this.totalAmount.orderPaying = item.total_amount
                    } else if(item.name === 'orderPaid'){
                        this.totalAmount.orderPaid = item.total_amount
                    } else if(item.name === 'orderPicking'){
                        this.totalAmount.orderPicking = item.total_amount
                    } else if(item.name === 'orderReceived'){
                        this.totalAmount.orderReceived = item.total_amount
                    }
                })
            })
        },
        goNextPage(page){
            let uid = this.isLogin ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
            switch(page){
                case 'address':
                    this.$router.push({ path: '/address', query:{ from:'personal' } });
                    break;
                case 'coupon':
                    this.$router.push('/coupon');
                    break;
                case 'create':
                    this.$router.push('/create');
                    break;
                case 'draft':
                    this.$router.push('/draft-box');
                    break;
                case 'home':
                    this.$router.push('/home');
                    break;
                case 'about':
                    this.$router.push('/about');
                    break;
                case 'service':
                    this.$router.push('/customer-service');
                    break;
                case 'editPersonal':
                    this.$router.push('/edit-personal');
                    break;
                case 'author':
                    this.$router.push({ path:'/author', query:{uid: uid}})
                    break;
                case 'feedback':
                    this.$router.push({ path:'/feedback' })
                    break;
                case 'allorder':
                    sessionStorage.setItem('tabId', '0')
                    sessionStorage.setItem('fromPath', 'personal')
                    this.$router.push({ path:'/order', query:{tabId: '0'}})
                    break;
                case 'paying':
                    sessionStorage.setItem('tabId', '1')
                    sessionStorage.setItem('fromPath', 'personal')
                    this.$router.push({ path:'/order', query:{tabId: '1'}})
                    break;
                case 'toBeDelivered':
                    sessionStorage.setItem('tabId', '2')
                    sessionStorage.setItem('fromPath', 'personal')
                    this.$router.push({ path:'/order', query:{tabId: '2'}})
                    break;
                case 'toBeReceived':
                    sessionStorage.setItem('tabId', '3')
                    sessionStorage.setItem('fromPath', 'personal')
                    this.$router.push({ path:'/order', query:{tabId: '3'}})
                    break;
                case 'customerService':
                    sessionStorage.setItem('tabId', '4')
                    sessionStorage.setItem('fromPath', 'personal')
                    this.$router.push({ path:'/order', query:{tabId: '4'}})
                    break;
                default:
                    break;
            }
        },
        seoInit(){
            // seo
            document.title = '我的' +  seo.config.appName
            let keywords = seo.config.seoPage.personalPage.keywords
            let description = '欢迎来到'+ this.userInfo.nickname + seo.config.seoPage.personalPage.description.split('#')[1]
            document.querySelector('meta[name="keywords"]').setAttribute('content', keywords)
            document.querySelector('meta[name="description"]').setAttribute('content', description)
        },
        wxShare(wxShareImgUrl){
            let url = ''
            if(this.$route.fullPath.includes('/personal')){
                url = seo.config.wxConfig.personalPage.link + this.$route.fullPath + '?share=wx'
            } else if(this.$route.fullPath.includes('/author')){
                url = seo.config.wxConfig.personalPage.link + this.$route.fullPath + '&share=wx'
            }
            let title = this.userInfo.nickname + '的个人主页'
            wxSdk({ url:url }).then(res=>{
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: seo.config.wxConfig.appId, // 必填，公众号的唯一标识
                    timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
                    signature: res.data.data.signature,// 必填，签名
                    jsApiList: ['checkJsApi', 'updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'] // 必填，需要使用的JS接口列表
                });
                wx.ready(function () {
                wx.checkJsApi({
                    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'], // 必填，需要使用的JS接口列表
                    success: function(res) {
                    // “分享给朋友”及“分享到QQ”
                    if (res.checkResult.updateAppMessageShareData || res.checkResult.onMenuShareAppMessage) {
                        wx.updateAppMessageShareData({ 
                            title: title, // 分享标题
                            desc: '快来和我一起参与媒辅助社区吧~', // 分享描述
                            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                            imgUrl: wxShareImgUrl, // 分享图标
                            success: function () {
                                // 设置成功
                            }
                        })
                    }
                    // “分享到朋友圈”及“分享到 QQ 空间”
                    if(res.checkResult.updateTimelineShareData || res.checkResult.onMenuShareTimeline){
                        wx.updateTimelineShareData({ 
                            title: title, // 分享标题
                            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                            imgUrl: wxShareImgUrl, // 分享图标
                            success: function () {
                                // 设置成功
                            }
                        })
                    }
                }})
                })
            })
        },
    },
    created(){
        this.getUserInfo()
        getopenIdByCode()
    },
    activated(){
        this.seoInit()
        this.initOrderAmount() // 更新徽标
    }
}
</script>

<style scoped lang="scss">
.personal-box {
    max-width: 625px;
    margin: 0 auto;
    position: relative;
    .avatar {
        display: flex;
        align-items: center;
        padding: 25px 15px 150px 25px;
        min-height: 80px;
        background-image: linear-gradient(to bottom, #fff, #f3f3f3);
        img {
            border-radius: 50%;
        }
        .phoneNum {
            padding-left: 15px;
            color: #222;
            font-size: 22px;
        }
    }
    .row {
        position: absolute;
        left: 0;
        right: 0;
        top: 130px;
        display: flex;
        justify-content: space-between;
        background: #fff;
        color: #222;
        margin: 0 15px 15px;
        padding: 25px 15px;
        border-radius: 20px;
    }
    .panel {
        padding-bottom: 20px;
        margin-bottom: 70px;
        .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 25px;
            font-weight: 600;
            color: #222;
        }
    }
}
::v-deep .van-uploader__input-wrapper {
    position: relative;
    width: 100%;
}
</style>